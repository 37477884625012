import commonStore from '@/common/commonStore';
import { computed } from 'vue';

const ACTION = {
    EVENT_VIEW: 'event_view',
    EVENT_UPDATE: 'event_update',
    EVENT_DELETE: 'event_delete',

    EMPLOYEE_STATUS_UPDATE: 'employee_status_update',
};

const EVENT_FIELD = {
    CLIENTS_AND_BOOKINGS: ['arrival_time', 'departure_time', 'notes', 'files', 'color_reaction'],
    CONTACTS: ['fio', 'phone', 'additional_phone', 'email', 'send_confirm_email', 'source_id'],
    AMOUNTS: ['amount', 'day_amount', 'payments_and_deposits'],
    AUTOMESSAGES: ['automessages_enabled'],
};

const ROLE = {
    // если ключа нет, то доступа нет
    CLEANER: {
        VIEW: {
            EVENT_CLIENT_AND_BOOKING_FIELDS: EVENT_FIELD.CLIENTS_AND_BOOKINGS,
            EVENT_CONTACT_FIELDS: EVENT_FIELD.CONTACTS,
            EVENT_AMOUNT_FIELDS: EVENT_FIELD.AMOUNTS,
        },
        UPDATE: {
            EVENT_CLIENT_AND_BOOKING_FIELDS: EVENT_FIELD.CLIENTS_AND_BOOKINGS,
            EVENT_CONTACT_FIELDS: EVENT_FIELD.CONTACTS.filter((field) => field !== 'source_id'),
        },
    },
    // если ключа нет, то доступ есть
    COMMON: {
        VIEW: {
            EVENT_CONTACT_FIELDS: EVENT_FIELD.CONTACTS,
            EVENT_AMOUNT_FIELDS: EVENT_FIELD.AMOUNTS,
            EVENT_AUTOMESSAGES_FIELDS: EVENT_FIELD.AUTOMESSAGES,
        },
    },
};

const userAccess = computed(() => {
    const chessmate = commonStore.state.user.chessmate.access;
    const settings = commonStore.state.user.settings.access;
    return [
        ...Object.keys(chessmate).filter((key) => chessmate[key]),
        ...Object.keys(settings).filter((key) => settings[key]),
    ];
});

const isCleaner = computed(() => commonStore.state.user.is_cleaner);
const isRubleCurrency = computed(() => commonStore.getters.isRubleCurrency);

const can = (action, field = '') => {
    // TODO: Удалить проверку на ff после подключения всех пользователей
    if (!commonStore.getters.hasFeatureFlag('cleaner_permissions')) {
        return true;
    }
    if (isCleaner.value) {
        if (action === ACTION.EVENT_UPDATE) {
            if (ROLE.CLEANER.UPDATE.EVENT_CONTACT_FIELDS.includes(field)) {
                return userAccess.value.includes('contacts');
            }
            if (ROLE.CLEANER.UPDATE.EVENT_CLIENT_AND_BOOKING_FIELDS.includes(field)) {
                return userAccess.value.includes('request_bookings');
            }
            return false;
        }

        if (action === ACTION.EVENT_VIEW) {
            if (ROLE.CLEANER.VIEW.EVENT_AMOUNT_FIELDS.includes(field)) {
                return userAccess.value.includes('booking_amounts');
            }
            if (ROLE.CLEANER.VIEW.EVENT_CONTACT_FIELDS.includes(field)) {
                return userAccess.value.includes('contacts');
            }
            if (ROLE.CLEANER.VIEW.EVENT_CLIENT_AND_BOOKING_FIELDS.includes(field)) {
                return userAccess.value.includes('request_bookings');
            }
            return false;
        }

        if (action === ACTION.EVENT_DELETE) {
            return false;
        }

        if (action === ACTION.EMPLOYEE_STATUS_UPDATE) {
            return false;
        }

        return false;
    } else {
        if (action === ACTION.EVENT_VIEW) {
            if (ROLE.COMMON.VIEW.EVENT_AMOUNT_FIELDS.includes(field)) {
                return userAccess.value.includes('booking_amounts');
            }
            if (ROLE.COMMON.VIEW.EVENT_CONTACT_FIELDS.includes(field)) {
                return userAccess.value.includes('contacts');
            }
            if (ROLE.COMMON.VIEW.EVENT_AUTOMESSAGES_FIELDS.includes(field)) {
                return userAccess.value.includes('automessages');
            }
            return true;
        }

        if (action === ACTION.EVENT_DELETE) {
            return true;
        }

        if (action === ACTION.EMPLOYEE_STATUS_UPDATE) {
            return true;
        }

        return true;
    }
};

// Проверки для действий на странице

const canEventView = (field) => {
    return can(ACTION.EVENT_VIEW, field);
};
const canEventUpdate = (field) => {
    return can(ACTION.EVENT_UPDATE, field);
};
const canEventDelete = () => {
    return can(ACTION.EVENT_DELETE);
};
// TODO: Удалить после подключения всех пользователей
const canEmployeeRoleAndStatusUpdate = () => {
    // Проверка должна работать на страницах без включенного ff cleaner_permissions,
    // с активным ff у горничной не будет доступа на страницу, где применяется проверка
    return !isCleaner.value;
};

// Проверки доступов к страницам

// Общая проверка для страниц, доступы к которым выдаются через настройки
const isAccess = (module) => {
    if (module === 'payment_settings') {
        return userAccess.value.includes(module) && isRubleCurrency.value;
    }
    return userAccess.value.includes(module);
};

const isAccessForCleaner = () => {
    // TODO: Удалить проверку на ff после подключения всех пользователей
    if (!commonStore.getters.hasFeatureFlag('cleaner_permissions')) {
        return true;
    }
    return !isCleaner.value;
};

// Проверка на доступ к странице создания-редактирования объекта (доступы не регулируются в настройках)
const isAccessApartmentPage = () => {
    return isAccessForCleaner();
};

// Проверка на доступ к странице просмотра статистики монеты (доступы не регулируются в настройках)
const isAccessMonetaStatisticPage = () => {
    return isAccessForCleaner();
};

export {
    userAccess,
    canEventView,
    canEventUpdate,
    canEventDelete,
    canEmployeeRoleAndStatusUpdate,
    isAccess,
    isAccessApartmentPage,
    isAccessMonetaStatisticPage,
};
