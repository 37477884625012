<template>
  <div :class="props.className?.wrapper">
      <label
        v-if="props.label"
        :for="id"
        class="col-form-label-sm"
        :class="[props.className?.label, {'label-required': props.required}]"
      >
        {{ props.label }}
      </label>
      <div
        class="d-flex flex-column"
        :class="props.className?.selectWrapper"
      >
        <div class="d-flex align-items-center gap-2">
          <select
            v-model="selectValue"
            class="form-select form-select-sm"
            :id="id"
            :disabled="props.disabled"
            :class="[props.className?.select, {'input-error': props.error}]"
          >
            <option
              v-if="props.blankOptionText"
              value=""
            >
              {{ props.blankOptionText }}
            </option>
            <option
              :key="option[props.valueKey]"
              :value="option[props.valueKey]"
              :disabled="option.disabled"
              v-for="option in props.options"
            >
              {{ option.name }}
            </option>
          </select>
          <div
            v-if="hasAfter"
            :class="props.className?.after"
            class="ms-1"
          >
            <slot name="after"/>
          </div>
        </div>
        <span
          v-if="props.description"
          class="color-grey fs-9 my-1"
          :class="props.className?.description"
        >
          {{ props.description }}
        </span>

        <p
          v-if="props.error"
          class="small color-error my-1"
          :class="props.className?.select"
        >
          {{ props.error }}
        </p>
      </div>
    </div>
</template>

<script setup>
import { computed, onBeforeUpdate, ref, useSlots } from 'vue';
import stringHelper from '@/common/helpers/stringHelper';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Number, Boolean, null],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: true,
  },
  blankOptionText: {
    type: String,
    default: '',
  },
  className: {
    type: Object,
  },
  required: {
    type: Boolean,
    default: false,
  },
  valueKey: {
    type: String,
    default: 'id',
  },
  error: {
    type: [String, Boolean],
    default: '',
  },
  description: String,
});

const slots = useSlots();
const hasAfter = ref(!!slots.after);

const id = stringHelper.getRandomUUID();

const emit = defineEmits(['update:value']);

const selectValue = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('update:value', value);
  },
});

onBeforeUpdate(() => {
  hasAfter.value = !!slots.after;
});
</script>

<style scoped>
.form-select {
  cursor: pointer;
}
</style>
